import { BaseModel } from '../../base.model';
import { OrganizationImage } from '@organization-management/organization/organization-image.model';
import { OrganizationBootstrapProperties } from './organization-bootstrap-properties.model';

export class Organization extends BaseModel<Organization> {
  created: Date;
  dataAnalyst: boolean;
  name: string;
  organizationBootstrapProperties: OrganizationBootstrapProperties;

  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  companyOverview?: string;
  companyUri?: string;
  contactEmailAddress?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactPrimaryPhone?: string;
  developer?: boolean;
  financier?: boolean;
  credit?: boolean;
  oapEnabled?: boolean;
  embeddedDashboardEnabled?: boolean;
  zohoAccountId?: string;
  headquartersLocation?: string;
  languagesSpoken?: string;
  numberOfEmployees?: number;
  otherLocations?: string;
  postalCode?: string;
  province?: string;
  slug?: string;
  suiteNumber?: string;
  vendor?: boolean;
  yearsInBusiness?: number;
  logoImage?: OrganizationImage;

  constructor(organizationInfo: any) {
    super();
    this.addressLineOne = organizationInfo.addressLineOne;
    this.addressLineTwo = organizationInfo.addressLineTwo;
    this.city = organizationInfo.city;
    this.companyOverview = organizationInfo.companyOverview;
    this.companyUri = organizationInfo.companyUri;
    this.contactEmailAddress = organizationInfo.contactEmailAddress;
    this.contactFirstName = organizationInfo.contactFirstName;
    this.contactLastName = organizationInfo.contactLastName;
    this.contactPrimaryPhone = organizationInfo.contactPrimaryPhone;
    this.developer = organizationInfo.developer;
    this.financier = organizationInfo.financier;
    this.credit = organizationInfo.credit;
    this.oapEnabled = organizationInfo.oapEnabled;
    this.embeddedDashboardEnabled = organizationInfo.embeddedDashboardEnabled;
    this.zohoAccountId = organizationInfo.zohoAccountId;
    this.headquartersLocation = organizationInfo.headquartersLocation;
    this.id = organizationInfo.id;
    this.languagesSpoken = organizationInfo.languagesSpoken;
    this.name = organizationInfo.name;
    this.numberOfEmployees = organizationInfo.numberOfEmployees;
    this.otherLocations = organizationInfo.otherLocations;
    this.postalCode = organizationInfo.postalCode;
    this.province = organizationInfo.province;
    this.slug = organizationInfo.slug;
    this.suiteNumber = organizationInfo.suiteNumber;
    this.vendor = organizationInfo.vendor;
    this.dataAnalyst = organizationInfo.dataAnalyst;
    this.yearsInBusiness = organizationInfo.yearsInBusiness;
    this.logoImage = new OrganizationImage(organizationInfo.logoImage || {});
    this.created = organizationInfo.created;
    this.organizationBootstrapProperties = new OrganizationBootstrapProperties(organizationInfo.organizationBootstrapProperties || {});
  }

  get isOrganizationProfileValid(): boolean {
    return (this.name && this.name !== '') &&
           (this.headquartersLocation && this.headquartersLocation !== '') &&
           this.yearsInBusiness &&
           this.numberOfEmployees &&
           (this.contactFirstName && this.contactFirstName !== '') &&
           (this.contactLastName && this.contactLastName !== '') &&
           (this.contactEmailAddress && this.contactEmailAddress !== '') &&
           (this.logoImage && this.logoImage.id && this.logoImage.id !== '');
  }
}
