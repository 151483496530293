import { Injectable } from '@angular/core';

@Injectable()
export class FormBuilderService {

  constructor() {}

  translateComponent(component, lang) {
    const langOrder = ['en', 'es', 'fr', 'pt'];
    const langIndex = langOrder.findIndex(l => l === lang);

    if (component.type === 'selectboxes' || component.type === 'radio' || component.type === 'select') {
      component.values?.forEach(option => {
        if (option.label?.includes('||')) {
          const translations = option.label.split('||').map(label => label.trim());
          if (langIndex !== -1 && translations[langIndex]) {
            option.label = translations[langIndex];
          } else {
            option.label = translations[0];
          }
        }
      });
    }
    if (component?.label?.includes('||')) {
      const translations = component.label.split('||').map(label => label.trim());
      if (langIndex !== -1 && translations[langIndex]) {
        component.label = translations[langIndex];
      } else {
        component.label = translations[0];
      }
    }

    if (component.components) {
      component.components.forEach(c => this.translateComponent(c, lang));
    }

    return component;
  }
}
