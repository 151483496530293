<oes-read-only-display
  [readOnly]="readOnly">
</oes-read-only-display>

<ng-container *ngIf="project && readOnly != null">

  <ng-container *ngIf="showCloseButton">
    <oes-button
      class="btn btn-gray btn-tertiary"
      id="closeButton"
      (click)="close()"
      [leadingIconSrc]="'/assets/icons/line-icons/shared/black/x.svg'"
      >{{ 'buttons.close' | translate }}</oes-button>
  </ng-container>

  <ng-container *ngIf="showMilestonesInOneList; else SeparateLists">
    <oes-milestone-list
      [addMilestone]="false"
      [emptyMessage]="'milestones.reporting.empty-message' | translate"
      [isLoading]="isLoading"
      [isProgramOwner]="programOwner"
      [milestones]="programMilestones.concat(projectMilestones)"
      [milestoneType]="[milestoneTypes.PROGRAM, milestoneTypes.PROJECT]"
      [project]="project"
      [readOnly]="readOnly"
      [projectOfftaker]="projectOfftaker"
      [projectInstaller]="projectInstaller"
      [showCount]="showCount"
      [showExport]="showExport"
      [title]="'milestones.milestones' | translate"
      [emptyListContent]="emptyListContent"
    ></oes-milestone-list>
  </ng-container>

  <ng-template #SeparateLists>
    <div class="mb-5">
      <oes-milestone-list
        [addMilestone]="false"
        [emptyMessage]="'milestones.reporting.empty-message' | translate"
        [isLoading]="isLoading"
        [isProgramOwner]="isProgramOwner"
        [milestones]="programMilestones"
        [projectOfftaker]="projectOfftaker"
        [projectInstaller]="projectInstaller"
        [milestoneType]="milestoneTypes.PROGRAM"
        [project]="project"
        [readOnly]="readOnly"
        [title]="'milestones.reporting.heading' | translate"
      ></oes-milestone-list>
    </div>

    <div class="mb-5">
      <oes-milestone-list
        [addMilestone]="true"
        [emptyMessage]="'milestones.developer.empty-message' | translate"
        [isLoading]="isLoading"
        [milestones]="projectMilestones"
        [projectOfftaker]="projectOfftaker"
        [projectInstaller]="projectInstaller"
        [milestoneType]="milestoneTypes.PROJECT"
        [project]="project"
        [readOnly]="readOnly"
        [title]="'milestones.developer.heading' | translate"
      ></oes-milestone-list>
    </div>
  </ng-template>
</ng-container>

<div *ngIf="isLoadingModal" class="loading-ring-container">
  <div class="loading-ring"><div></div><div></div><div></div><div></div></div>
</div>
<oes-milestone-edit
  [project]="project"
  [user]="currentUser"
  (isLoading)="setIsLoadingModal($event)"
></oes-milestone-edit>
<oes-milestone-share></oes-milestone-share>
<oes-milestone-config (isLoading)="setIsLoadingModal($event)"></oes-milestone-config>
