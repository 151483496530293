export enum Theme {
  A2EI = 'a2ei',
  ABER = 'aber',
  AGER = 'ager',
  AMADER = 'amader',
  AMADERMALI = 'amadermali',
  AMAP = 'amap',
  ANPER = 'anper',
  ARENE = 'arene',
  ASER = 'aser',
  AVORENEWABLES = 'avorenewables',
  BENIN = 'benin',
  BRILHO = 'brilho',
  BURUNDI = 'burundi',
  CB = 'cb',
  CEIAFRICA = 'ceiafrica',
  CPI = 'cpi',
  DARES = 'dares',
  DB = 'db',
  DREAMETHIOPIA = 'dream-ethiopia',
  EARF = 'earf',
  ECREEE = 'ecreee',
  EEU = 'eeu',
  EPP = 'epp',
  ESMAPHFE = 'esmap-hfe',
  ESSOR = 'essor',
  FUNAE = 'funae',
  FONDSDECIM = 'fondsdecim',
  GBEBENIN = 'gbe-benin',
  GBECOTEDIVOIRE = 'gbe-cotedivoire',
  IFC = 'ifc',
  IPFF = 'ipff',
  NEA = 'nea',
  NERC = 'nerc',
  OGEF = 'ogef',
  OMDF = 'omdf',
  ODYSSEY = 'odyssey',
  PAOP = 'paop',
  PHARES = 'phares',
  POWERTRUST = 'powertrust',
  PROENERGIA = 'proenergia',
  REA = 'rea',
  REAEEI = 'reaeei',
  REAREF = 'rearef',
  RENSOURCE = 'rensource',
  RMI = 'rmi',
  RREALIBERIA = 'rrealiberia',
  SOMALILAND = 'somaliland',
  SUNAFRICA = 'sun-africa',
  TCHAD = 'tchad',
  TDBGROUP = 'tdbgroup',
  UEF = 'uef',
}
