import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { TextFilterOption } from '@shared/components/files/shared/text-filter-option.enum';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { ProjectType } from '../project-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ColumnConfigFactory extends BaseAgGridSettingsService {
  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;
  columnConfigs: any;
  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();
  projectTypeTranslations: any;
  translations: any;
  units: any;

  private _languageService: any;
  private fixedDecimals: number = 2;
  private textFilterOptions = [
    TextFilterOption.contain,
    TextFilterOption.notContain,
    TextFilterOption.equal,
    TextFilterOption.notEqual
  ];

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _currencyPipe: CurrencyPipe) {
    super(_agGridServicesWrapperService);
    this.translations = _agGridServicesWrapperService._translateService.instant('projects.column');
    this.units = _agGridServicesWrapperService._translateService.instant('units');
    this.projectTypeTranslations = _agGridServicesWrapperService._translateService.instant('project-type');
    this.columnConfigs = this.getColumnConfigs();
    this._languageService = _agGridServicesWrapperService._languageService;
  }

  public get(fieldName: string) {
    return this.columnConfigs[fieldName];
  }

  public formatProjectType(params: any, projectTypeTranslations: any) {
    if (params.value && params.value.trim() !== '') {
      if (params.value === 'MINIGRID') {
        return projectTypeTranslations['minigrid'];
      }
      if (params.value === 'MESHGRID') {
        return projectTypeTranslations['meshgrid'];
      }
      if (params.value === 'SOLAR_HOME_SYSTEM') {
        return projectTypeTranslations['solar-home-system'];
      }
      if (params.value === 'MARKET') {
        return projectTypeTranslations['market'];
      }
      if (params.value === 'PRODUCTIVE_USE_EQUIPMENT') {
        return projectTypeTranslations['productive-use-equipment'];
      }
      if (params.value === 'COOKSTOVE') {
        return projectTypeTranslations['cookstove'];
      }
      if (params.value === 'CI_SITE') {
        return projectTypeTranslations['ci'];
      }
    }
  }

  private getCIProjectCurrency(value: number, params: any) {
    const currency = params.data.alternateCurrency || 'USD';
    const symbol = currency === 'USD' ? 'symbol' : 'symbol-narrow';
    return this._currencyPipe.transform(value, currency, symbol, '1.2-2');
  }

  private getColumnConfigs() {
    return {
      payConnections: {
        sortable: false,
        field: 'payConnections',
        cellRenderer: (params) => {
          if (params.data) {
            return `<a href="#/oes/projects/shared-projects/${params.data.id}/connections?tab=payout"><button class="btn-ex-small btn-primary" style="line-height: 0; padding: 0 15px;">${this.translations['pay-connections']}</button></a>`;
          }
        }
      },
      checkbox: {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      },
      submittedConnections: {
        headerName: this.translations['submitted-connections'],
        headerTooltip: this.translations['submitted-connections'],
        field: 'totalConnectionsSubmitted',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      connectionsEverSubmitted: {
        headerName: this.translations['connections-ever-submitted'],
        headerTooltip: this.translations['connections-ever-submitted'],
        field: 'totalConnectionsEverSubmitted',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        }
      },
      connectionsVerifiedSubmitted: {
        headerName: this.translations['connections-verified'],
        headerTooltip: this.translations['connections-verified'],
        field: 'totalConnectionsVerifiedSubmitted',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        }
      },
      totalPaymentAmount: {
        headerName: this.translations['connections-amount-paid-in-currency'],
        headerTooltip: this.translations['connections-amount-paid-in-currency'],
        field: 'connectionsAmountPaidInCurrency',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        suppressSizeToFit: true,
        filterParams: {
          clearButton: true
        },
        valueFormatter: (params) => {
          if (!params?.value) {
            return '';
          }
          if (params?.data?.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params?.data?.connectionsAmountPaid, params);
          }
          if (params?.data?.projectPayoutCurrency === 'USD') {
            const amountUsd = { value: params.data.connectionsAmountPaid };
            return this.formatCurrency(amountUsd, this._languageService.getLanguage());
          }
          return (params?.data?.projectPayoutCurrency ? params.data.projectPayoutCurrency + ' ' : '') + new DecimalPipe(this._languageService.getLanguage()).transform(params.value, '1.2-2');
        }
      },
      totalClaimAmount: {
        headerName: this.translations['total-claim-amount-in-currency'],
        headerTooltip: this.translations['total-claim-amount-in-currency'],
        field: 'connectionsAmountPotentialInCurrency',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        suppressSizeToFit: true,
        filterParams: {
          clearButton: true
        },
        valueFormatter: (params) => {
          if (!params?.value) {
            return '';
          }
          if (params?.data?.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params?.data?.connectionsAmountPotential, params);
          }
          if (params?.data?.projectPayoutCurrency === 'USD') {
            const amountUsd = { value: params.data.connectionsAmountPotential };
            return this.formatCurrency(amountUsd, this._languageService.getLanguage());
          }
          return (params?.data?.projectPayoutCurrency ? params.data.projectPayoutCurrency + ' ' : '') + new DecimalPipe(this._languageService.getLanguage()).transform(params.value, '1.2-2');
        }
      },
      approvedConnections: {
        headerName: this.translations['approved-connections'],
        headerTooltip: this.translations['approved-connections'],
        field: 'totalConnectionsApproved',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      paidConnections: {
        headerName: this.translations['paid-connections'],
        headerTooltip: this.translations['paid-connections'],
        field: 'totalConnectionsPaid',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      rejectedConnections: {
        headerName: this.translations['rejected-connections'],
        headerTooltip: this.translations['rejected-connections'],
        field: 'totalConnectionsRejected',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      latitude: {
        headerName: this.translations['latitude'],
        headerTooltip: this.translations['latitude'],
        field: 'latitude',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        valueFormatter: (params) => {
          return new DecimalPipe(this._languageService.getLanguage()).transform(params.value, '1.3-3');
        },
        hide: true
      },
      longitude: {
        headerName: this.translations['longitude'],
        headerTooltip: this.translations['longitude'],
        field: 'longitude',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        valueFormatter: (params) => {
          return new DecimalPipe(this._languageService.getLanguage()).transform(params.value, '1.3-3');
        },
        hide: true
      },
      country: {
        headerName: this.translations['country'],
        field: 'country',
        headerTooltip: this.translations['country'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        },
        suppressSizeToFit: false
      },
      region: {
        headerName: this.translations['state'],
        field: 'region',
        headerTooltip: this.translations['state'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: false
      },
      totalCustomers: {
        headerName: this.translations['customers'],
        field: 'totalCustomers',
        headerTooltip: this.translations['customers'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        suppressSizeToFit: false,
        width: 150
      },
      totalPV: {
        headerName: this.translations['pv-capacity'],
        field: 'totalPV',
        headerTooltip: this.translations['pv-capacity'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        suppressSizeToFit: false,
        width: 150
      },
      subsidyAmountPerConnection: {
        headerName: this.translations['subsidyAmountPerConnection'],
        headerTooltip: this.translations['subsidyAmountPerConnection'],
        field: 'subsidyAmountPerConnection',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params?.data?.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      irr: {
        headerName: this.translations['irr'],
        headerTooltip: this.translations['irr'],
        field: 'irr',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        valueGetter: (params) => (params.data.irr === -10000 ? null : params.data.irr),
        valueFormatter: (params) => {
          if (params.value !== -10000) {
            return this.formatPercent(params);
          }
          return '';
        },
        filterParams: {
          clearButton: true
        },
      },
      payback: {
        headerName: this.translations['payback'],
        headerTooltip: this.translations['payback'],
        field: 'payback',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        // To hide invalid number
        valueGetter: (params) => (params.data.payback === -10000 ? null : params.data.payback),
        valueFormatter: (params) => {
          if (params.value !== -10000 && params.value) {
            if (params.value === 1) {
              return params.value + ' ' + this.translations['year'];
            } else {
              return params.value?.toFixed(0) + ' ' + this.translations['years'];
            }
          }
          return '';
        },
        filterParams: {
          clearButton: true
        },
      },
      lcoe: {
        headerName: this.translations['lcoe'],
        headerTooltip: this.translations['lcoe'],
        field: 'lcoe',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      totalCapex: {
        headerName: this.translations['total-capex'],
        headerTooltip: this.translations['total-capex'],
        field: 'totalCapex',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
      },
      capexPerConnection: {
        headerName: this.translations['capex-per-connection'],
        headerTooltip: this.translations['capex-per-connection'],
        field: 'capexPerConnection',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      cod: {
        headerName: this.translations['expected-cod'],
        headerTooltip: this.translations['expected-cod'],
        field: 'cod',
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.formatDate,
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true
        },
        hide: true
      },
      program: {
        headerName: this.translations['program'],
        field: 'program',
        headerTooltip: this.translations['program'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: false,
        cellRenderer: (params) => {
          if (params.value && params.data?.programId) {
            return `<a class="hyperlink" href="#/oes/finance/programs/${params.data.programId}/home">${params.value}</a>`;
          }
          return params.value;
        }
      },
      submittedToProgramDateTime: {
        headerName: this.translations['program-submission-date'],
        headerTooltip: this.translations['program-submission-date'],
        field: 'submittedToProgramDateTime',
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.formatDate,
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true
        },
        hide: true
      },
      status: {
        headerName: this.translations['status'],
        headerTooltip: this.translations['status'],
        field: 'status',
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        }
      },
      projectType: {
        headerName: this.translations['project-type'],
        headerTooltip: this.translations['project-type'],
        field: 'projectType',
        valueFormatter: (params) => this.formatProjectType(params, this.projectTypeTranslations),
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true,
          values: ['MINIGRID', 'SOLAR_HOME_SYSTEM', 'PRODUCTIVE_USE_EQUIPMENT', 'COOKSTOVE', 'CI_SITE'],
          valueFormatter: (params) => this.formatProjectType(params, this.projectTypeTranslations)
        }
      },
      arpuResidential: {
        headerName: this.translations['residential-arpu'],
        headerTooltip: this.translations['residential-arpu'],
        field: 'arpuResidential',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      arpuCommercial: {
        headerName: this.translations['commercial-arpu'],
        headerTooltip: this.translations['commercial-arpu'],
        field: 'arpuCommercial',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      arpuPublic: {
        headerName: this.translations['public-arpu'],
        headerTooltip: this.translations['public-arpu'],
        field: 'arpuPublic',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      arpuProductive: {
        headerName: this.translations['productive-arpu'],
        headerTooltip: this.translations['productive-arpu'],
        field: 'arpuProductive',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      arpuAnchor: {
        headerName: this.translations['anchor-arpu'],
        headerTooltip: this.translations['anchor-arpu'],
        field: 'arpuAnchor',
        unSortIcon: true,
        valueFormatter: (params) => {
          if (params.data.projectType === ProjectType.ci) {
            return this.getCIProjectCurrency(params.value, params);
          }
          return this.formatCurrency(params, this._languageService.getLanguage());
        },
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      equityIRR: {
        headerName: this.translations['equity-irr'],
        headerTooltip: this.translations['equity-irr'],
        field: 'equityIRR',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        valueFormatter: (params) => {
          if (params.value !== -10000) {
            return this.formatPercent(params);
          }
          return '';
        },
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      equityPayback: {
        headerName: this.translations['payback-to-equity'],
        headerTooltip: this.translations['payback-to-equity'],
        field: 'equityPayback',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        // To hide an invalid number
        valueFormatter: (params) => {
          if (params.value !== -10000) {
            return params.value;
          }
          return '';
        },
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      storage: {
        headerName: this.translations['storage'],
        headerTooltip: this.translations['storage'],
        field: 'storageSize',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        // To hide an invalid number
        valueFormatter: (params) => {
          if (params.value === -10000 || params.value === undefined) {
            return '';
          }
          return params.value.toFixed(this.fixedDecimals) + ' ' + this.units.kwh;
        },
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      generator: {
        headerName: this.translations['generator'],
        headerTooltip: this.translations['generator'],
        field: 'generatorSize',
        unSortIcon: true,
        filter: 'agNumberColumnFilter',
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        // To hide an invalid number
        valueFormatter: (params) => {
          if (params.value === -10000 || params.value === undefined) {
            return '';
          }
          return params.value.toFixed(this.fixedDecimals) + ' ' + this.units.kva;
        },
        filterParams: {
          clearButton: true
        },
        hide: true
      },
      latestCompletedMilestone: {
        headerName: this.translations['latest-completed-milestone'],
        headerTooltip: this.translations['latest-completed-milestone'],
        field: 'milestones.latestCompletedMilestone',
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          applyButton: true,
          clearButton: true,
          filterOptions: this.textFilterOptions,
          // valueGetter response goes to this.textMatcher
          valueGetter: (params) => {
            return params?.data?.milestones?.latestCompletedMilestone;
          },
          textMatcher: this.textMatcher,
        },
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        width: 200,
        resizable: true,
        cellClass: 'tag-container',
        cellRenderer: (params) => {
          if (!params?.value) {
            return '';
          }
          return `<span class="linked-tag">
                    ${params.value}
                    <img
                      style="height: 9px; transform: rotate(180deg);" src="./assets/icons/line-icons/shared/grey/arrow-left.svg" />
                  </span>`;
        }
      },
      latestMilestoneUpdate: {
        headerName: this.translations['latest-milestone-update'],
        headerTooltip: this.translations['latest-milestone-update'],
        field: 'milestones.latestMilestoneUpdateDate',
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true,
          valueGetter: (params) => {
            return params?.data?.milestones?.latestMilestoneUpdateDate;
          }
        },
        enablePivot: true,
        enableRowGroup: true,
        resizable: true,
        width: 250,
        enableValue: true,
        valueFormatter: (params) => {
          if (!params?.value) {
            return '';
          }
          return this.getLocalDateWithTextTimezone(params, 'yyyy-MM-dd HH:mm');
        }
      },
      completedMilestones: {
        headerName: this.translations['completed-milestones'],
        headerTooltip: this.translations['completed-milestones'],
        field: 'milestones.completedMilestones',
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          applyButton: true,
          clearButton: true,
          filterOptions: this.textFilterOptions,
          // valueGetter response goes to this.textMatcher
          valueGetter: (params) => {
            return params?.data?.milestones?.completedMilestones.join(', ');
          },
          textMatcher: this.textMatcher,
        },
        enablePivot: true,
        enableRowGroup: true,
        width: 200,
        autoHeight: true,
        resizable: true,
        enableValue: true,
        hide: true,
        cellClass: 'tag-container',
        cellRenderer: (params) => {
          if (!params?.value?.length) {
            return '';
          }
          const bullets = params.value.map(milestoneName => {
            return `<span class="bullet-tag">${ milestoneName }</span>`;
          });
          if (bullets && bullets.length > 0) {
            return bullets.join('');
          }
        }
      },
      inProgressMilestones: {
        headerName: this.translations['in-progress-milestones'],
        headerTooltip: this.translations['in-progress-milestones'],
        field: 'milestones.inProgressMilestones',
        unSortIcon: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          applyButton: true,
          clearButton: true,
          filterOptions: this.textFilterOptions,
          // valueGetter response goes to this.textMatcher
          valueGetter: (params) => {
            return params?.data?.milestones?.inProgressMilestones.join(', ');
          },
          textMatcher: this.textMatcher,
        },
        enablePivot: true,
        enableRowGroup: true,
        width: 200,
        autoHeight: true,
        resizable: true,
        enableValue: true,
        hide: true,
        cellClass: 'tag-container',
        cellRenderer: (params) => {
          if (!params?.value?.length) {
            return '';
          }
          const bullets = params.value.map(milestoneName => {
            return `<span class="bullet-tag">${ milestoneName }</span>`;
          });
          if (bullets && bullets.length > 0) {
            return bullets.join('');
          }
        }
      },
      auditScore: {
        colId: 'evaluations',
        headerName: this.translations['audit-score'],
        headerTooltip: this.translations['audit-score'],
        field: 'evaluations',
        minWidth: 250,
        unSortIcon: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: 'cellAuditScoreComponent'
      }
    };
  }

  private textMatcher(params) {
    const valueArray = params.value.split('$separate$').map(item => item.toLowerCase());
    let result = false;
    const filterTextLower = params.filterText.toLowerCase();

    switch (params.filterOption) {
      case TextFilterOption.contain:
        result = !valueArray.every(item => !item.includes(filterTextLower));
        break;
      case TextFilterOption.notContain:
        result = valueArray.every(item => !item.includes(filterTextLower));
        break;
      case TextFilterOption.equal:
        result = valueArray.includes(filterTextLower);
        break;
      case TextFilterOption.notEqual:
        result = !valueArray.includes(filterTextLower);
        break;
    }
    return result;
  }
};
