import { Component, Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectListType } from '@project/shared-projects-list/project-list-type.enum';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { SelectedProjectsService } from '@project/shared/selected-projects.service';
import { SimpleProject } from '@project/shared/simple-project.model';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { View } from '@shared/components/view-toggle-button/view.enum';
import { EventService } from '@shared/event.service';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';
import { ColumnApi, GridApi, GridOptions, ISimpleFilterModel } from 'ag-grid-community';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin, Subject } from 'rxjs';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';

import { MyProjectGridSettingService } from './my-projects-grid-setting.service';
import { ProgramClaimsKPIService } from '@program/program-detail/program-claims/program-claims-kpi.service';
import { ProjectType } from '@project/shared/project-type.enum';
import { ReferenceService } from '@shared/references.service';
import { REFERENCE_TYPE } from '@shared/reference-types';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'oes-my-projects-list',
  templateUrl: 'my-projects-list.component.html',
  styleUrls: ['./my-projects-list.component.scss'],
  providers: [
  ],
})
export class MyProjectsListComponent implements OnInit, OnDestroy {
  @Input() programId: string;
  @Input() listType: ProjectListType = ProjectListType.MAIN;
  @ViewChild('deleteModal', {static: false}) deleteModal: ModalDirective;
  @ViewChild('myProjectsList', {static: false}) myProjectsList: any;

  activeProjectTypes: string[] = [];
  columnApi: ColumnApi;
  copying: boolean = false;
  currentView = View.LIST;
  defaultGridState: GridState;
  filterModel: ISimpleFilterModel;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  mapProjects: any[];
  milestoneDrawerLoaded: boolean = false;
  milestoneDrawerOpen: boolean = false;
  milestoneProject: Project;
  projects: any[]; // flatten Project
  projectListType = ProjectListType;
  projectStatuses: any[] = [];
  projectTypes: string[];
  selectedProjectIds = [];
  selectedProjectNames = [];
  view = View;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  private disableScrollClass = 'disable-scroll';
  private supressCellValueChangedEvent = false;

  constructor(private _activatedRoute: ActivatedRoute,
              private _dynamicHomeService: DynamicHomeService,
              private _eventService: EventService,
              public _myProjectGridSettingService: MyProjectGridSettingService,
              private _projectService: ProjectService,
              private _programClaimsKpiService: ProgramClaimsKPIService,
              private _referenceService: ReferenceService,
              private _renderer: Renderer2,
              private _selectedProjectsService: SelectedProjectsService,
              private _translateService: TranslateService,
              @Inject(DOCUMENT) private document: Document) {
    const types: string[] = Object.values(ProjectType);
    const typeIndexToRemove = types.findIndex(t => t === 'MARKET');
    types.splice(typeIndexToRemove, 1);  // Not showing MARKET
    this.projectTypes = types;
  }

  // callback delete modal
  get deleteProjectList() {
    return this.selectedProjectNames.join(',') + '.';
  }

  ngOnInit() {
    this.gridOptions = this._myProjectGridSettingService.getGridOptions(this.listType, this.projectStatuses, false);
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // ag-grid is ready
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._referenceService.getType(REFERENCE_TYPE.PROJECT_STATUS_TYPE).subscribe((projectStatuses) => {
      this.projectStatuses = projectStatuses;
      this.gridApi.setColumnDefs(this._myProjectGridSettingService.getColumnSetting(this.listType, projectStatuses));
    });
    this._myProjectGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._myProjectGridSettingService.buildDefaultGridState();
    this.getProjects();
  }

  onCellClicked(event: any) {
    if (event?.column?.colId === 'milestones.latestCompletedMilestone') {
      this._projectService.detail(event.data.id).subscribe((projectDetail: Project) => {
        this.milestoneProject = projectDetail;
        // Add to DOM
        this.milestoneDrawerLoaded = true;
        // Open drawer
        this.milestoneDrawerOpen = true;
        // Prevent scrolling MyProjects while drawer is open
        const navMainContainer = this.document.querySelector('.nav-main-container');
        if (navMainContainer) {
          this._renderer.addClass(navMainContainer, this.disableScrollClass);
        }
      });
    }
  }

  closeMilestoneDrawer() {
    this.refreshMilestones();
    this.milestoneDrawerOpen = false;
    // Unlock scrolling
    const navMainContainer = this.document.querySelector('.nav-main-container');
    if (navMainContainer) {
      this._renderer.removeClass(navMainContainer, this.disableScrollClass);
    }
    setTimeout(() => {
      // Unload drawer from DOM
      this.milestoneDrawerLoaded = false;
      this.milestoneProject = undefined;
    }, 500);
  }

  installSampleProject() {
    this._eventService.success(this._translateService.instant('success-message.sample-project-creating'));
    this._projectService.createSampleProject()
    .pipe(take(1))
    .subscribe((project: Project) => {
      this.getProjects();
      this._eventService.success(this._translateService.instant('success-message.sample-project-created'));
    });
  }

  // callback project-action
  exportProjectFinancialModel() {
    if (this.selectedProjectIds) {
      this._projectService.exportProjectFinancialModelAsync(this.selectedProjectIds);
    }
  }

  // callback project-action
  copyProjects() {
    this._eventService.success(this._translateService.instant('success-message.copying-projects'));
    const observables = this.selectedProjectIds.map(id => this._projectService.copy(id));
    // clear selected projects
    this._selectedProjectsService.projects = undefined;
    forkJoin(observables)
    .pipe(
      take(1),
      finalize(() => this.copying = false)
    )
    .subscribe((projects: Project[]) => {
      this.selectedProjectIds = [];
      this.selectedProjectNames = [];
      this.getProjects();
      this._eventService.success(this._translateService.instant('success-message.projects-copied'));
    });
  }

  // callback delete modal
  deleteProjects() {
    this.deleteModal.show();
    const observables = this.selectedProjectIds.map(id => this._projectService.remove(id));

    forkJoin(observables)
    .pipe(take(1))
    .subscribe((response: any[]) => {
      this._eventService.success(this._translateService.instant('general-message.success.deleted'));
      this.selectedProjectIds = [];
      this.selectedProjectNames = [];
      this.deleteModal.hide();
      this.getProjects();
    },
    error => {
      this._eventService.error(error?.error?.message);
      this.deleteModal.hide();
    });
  }

  // callback delete button on project-action
  prepareDelete() {
    this.deleteModal.show();
  }

  // callback delete modal
  cancelDelete() {
    this.selectedProjectIds = [];
    this.selectedProjectNames = [];
    this.deleteModal.hide();
  }

  // ag-grid callback: checkbox
  selectionChanged(event) {
    const selectedProjects = event.api.getSelectedNodes().map(item => item.data);
    this._selectedProjectsService.projects = selectedProjects;
    this.selectedProjectNames = selectedProjects.map(item => item.name);
    this.selectedProjectIds = selectedProjects.map(item => item.id);
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._myProjectGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
      this.filterModel = this.gridApi.getFilterModel();
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._myProjectGridSettingService.exportCsv(this.gridApi, 'MyProjects', true);
        break;
      case GridSubAction.reload:
        this.getProjects();
        break;
      case GridSubAction.clearFilter:
        this._myProjectGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        this.storeGridState();
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._myProjectGridSettingService.buildGridStateStorageKey('myProjects');
    this.checkQueryParams();
  }

  onCellValueChanged(event) {
    if (event.colDef.field === 'status' && event.data.id && event.newValue) {
      if (this.supressCellValueChangedEvent) {
        this.supressCellValueChangedEvent = false;
        return;
      }
      this._projectService.detail(event.data.id).pipe(
        switchMap((projectDetail: Project) => {
          const readOnly = projectDetail.projectPermissions?.project?.modulePermissions?.information?.readOnly;
          if (readOnly) {
            this.supressCellValueChangedEvent = true;
            event.node.setDataValue(event.colDef.field, event.oldValue);
            this._eventService.error(this._translateService.instant('project.message.update-not-allowed'));
            return [];
          } else {
            projectDetail.status = event.newValue;
            return this._projectService.createUpdate(projectDetail);
          }
        })
      ).subscribe({
        next: () => {
          this._eventService.success(this._translateService.instant('project.message.update-success'));
        },
        error: () => {
          this._eventService.error(this._translateService.instant('project.message.updated-failed'));
        }
      });
    }
  }

  private getProjects() {
    if (this.listType !== ProjectListType.MAIN && this.programId) {
      this._projectService.myListByProgram(this.programId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projects: SimpleProject[]) => {
        this.currentView = View.LIST;
        this.setAgGrid(projects);
        this.mapProjects = undefined;
        if (this.listType === ProjectListType.CLAIMS) {
          this._programClaimsKpiService.buildClaimsKPIs(projects);
        }
      });
    } else {
      const requests = [this._projectService.myList(),
                        this._projectService.projectMilestoneMap()];
      forkJoin(requests).subscribe(responses => {
        const projects: SimpleProject[] = responses[0];
        const milestonesMap: any = responses[1];
        if (this.currentView === View.LIST) {
          let combinedProjects = this.addMilestoneMap(projects, milestonesMap);
          this.setAgGrid(combinedProjects);
          combinedProjects = undefined;
          this.mapProjects = undefined;
        } else if (this.currentView === View.MAP) {
          this.mapProjects = projects;
        }
        this._dynamicHomeService.saveUrl();
      });
    }
  }

  private addMilestoneMap(projects: SimpleProject[], milestonesMap: any): SimpleProject[] {
    return projects.map(project => {
      if (milestonesMap[project.id]) {
        project.milestones = milestonesMap[project.id];
      }
      return project;
    });
  }

  private refreshMilestones() {
    this._projectService.projectMilestoneMap()
    .pipe(take(1))
    .subscribe((milestonesMap: any) => {
      const projectsWithMilestones = this.addMilestoneMap(this.projects, milestonesMap);
      this.setAgGrid(projectsWithMilestones);
    });
  }

  private setAgGrid(projects: SimpleProject[]) {
    if (projects?.length > 0) {
      this.gridApi.showLoadingOverlay();
      this.projects = projects;
      this.gridApi.setRowData(this.projects);
      this._myProjectGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.hideOverlay();
      const tableHeight = `${this.projects.length * 80 + 42}px`;
      this.myProjectsList._nativeElement.style.setProperty('height', tableHeight, 'important');
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  private checkQueryParams() {
    this._activatedRoute.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      if (params?.type) {
        this.currentView = params.type;
        if (this.currentView === View.MAP) {
          this.getProjects();
        }
      }
    });
  }
}
