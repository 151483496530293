<ng-container *ngIf="listType === projectListType.MAIN">
  <oes-nav-header></oes-nav-header>
</ng-container>
<div class="container-fluid" style="padding: 0;">
  <!-- Action buttons -->
  <ng-container *ngIf="listType === projectListType.MAIN || listType === projectListType.ASSETS">
    <h3 [ngClass]="{'assets': listType === projectListType.ASSETS}"
    >{{ 'project-tabs.list' | translate }}</h3>
    <oes-project-action
      [projectListItems]="projects"
      [listType]="listType"
      (installSampleProject)="installSampleProject()"
      (exportProjectsFinancials)="exportProjectFinancialModel()"
      (copyProjects)="copyProjects()"
      (deleteProjects)="prepareDelete()">
    </oes-project-action>
  </ng-container>
  <oes-grid-sub-action
    class="project-list-sub-actions"
    *ngIf="gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    (action)="subAction($event)">
  </oes-grid-sub-action>

  <!-- Project List -->
  <ng-container *ngIf="currentView === view.LIST">
    <div class="grid ag-grid-header-sticky"
         [ngClass]="{ 'top-offset': programId !== undefined }"
         oesAgHorizontalScroll
         oesAgColumns>
      <ag-grid-angular
        #myProjectsList
        id="myProjectsList"
        style="width: auto; height: calc(100vh - 214px);"
        class="grid ag-theme-alpine"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (selectionChanged)="selectionChanged($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (filterChanged)="gridStatusChanged($event, 'filter')"
        (sortChanged)="gridStatusChanged($event, 'sort')"
        (displayedColumnsChanged)="gridStatusChanged($event, 'column')"
        (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>
    </div>
  </ng-container>

  <!-- MAP -->
  <ng-container *ngIf="currentView === view.MAP">
    <oes-project-map
      *ngIf="mapProjects"
      [projects]="mapProjects">
    </oes-project-map>
  </ng-container>
</div>

<!-- Delete warning modal -->
<div bsModal #deleteModal="bs-modal"
     [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
     class="modal fade delete-modal" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header-destructive">
        <h4 class="modal-title pull-left">{{ 'project-list.delete-modal.title' | translate }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelDelete()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column">
        <div>
          <p>
            {{ 'project-list.delete-modal.message' | translate }}
            <span>{{ deleteProjectList }}</span>
            {{ 'project-list.delete-modal.message-end' | translate }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancelDelete()">
          {{ 'buttons.cancel' | translate }}
        </button>
        <button type="button" class="btn btn-destructive" (click)="deleteProjects()">
          {{ 'buttons.delete' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="milestoneDrawerLoaded" class="milestones-container">
  <oes-side-drawer [isOpen]="milestoneDrawerOpen" (close)="closeMilestoneDrawer()">
    <oes-project-milestones
      [project]="milestoneProject"
      [showCount]="false"
      [showListExport]="false"
      [showMilestonesInOneList]="true"
      [showCloseButton]="true"
      (closeComponent)="closeMilestoneDrawer()"
    ></oes-project-milestones>
  </oes-side-drawer>
</div>
