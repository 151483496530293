import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { take } from 'rxjs/operators';
import { FilesService } from '@shared/components/files/shared/files.service';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { AddTag } from '@shared/components/files/shared/models/add-tags.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'oes-tag-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class TagAddComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  public formGroup: UntypedFormGroup;
  public tags: any[];

  constructor(private _documentDataService: DocumentDataService,
              private _filesService: FilesService,
              private _sanitizer: DomSanitizer,
              private _translateService: TranslateService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      tag: new UntypedFormControl('', Validators.required)
    });
  }

  onKeyDown(e) {
    if (e.keyCode === 13 && this.formGroup.valid) {
      this.add();
    }
  }

  public show() {
    this.tags = this._filesService.tagList;
    this.modalDialog.show();
  }

  public hide() {
    this.formGroup.controls['tag'].reset();
    this.modalDialog.hide();
  }

  public add() {
    const tag = this._sanitizer.sanitize(SecurityContext.HTML, this.formGroup.controls['tag'].value);
    if (tag && tag !== '') {
      const selectedNode = this._filesService.getHighlightedNodes();
      const documentIds = selectedNode.map(node => node.data.id);
      const addTag: AddTag = {
        documentIds: documentIds,
        tag: tag,
      };
      // find tag.id, if there is no tag.id, it will be a new tag
      const id = this._filesService.getTagId(tag);
      if (id && id !== '') {
        addTag['id'] = id;
      }
      if (tag && tag !== '') {
        this._documentDataService.addUserTag(addTag)
        .pipe(take(1))
        .subscribe(result => {
          this._filesService.clickActionButton(ActionType.completed);
          this._eventService.success(this._translateService.instant('data-room.dialogs.tag.added'));
          this.hide();
        });
      }
    }
  }
}
