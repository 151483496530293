export enum VerificationStatus {
  // no icon and text
  NOT_VERIFIED = 'NOT_VERIFIED',

  // green check with text
  VERIFIED = 'VERIFIED',

  // question mark with text
  DATA_NOT_FOUND = 'DATA_NOT_FOUND',
  PROGRAM_NOT_FOUND = 'PROGRAM_NOT_FOUND',
  VERIFICATION_RULE_NOT_FOUND = 'VERIFICATION_RULE_NOT_FOUND',
  PAYMENT_NOT_FOUND = 'PAYMENT_NOT_FOUND',
  METER_READING_NOT_FOUND = 'METER_READING_NOT_FOUND',
  DATA_OUT_OF_DATE_RANGE = 'DATA_OUT_OF_DATE_RANGE',
  ERROR_DURING_VERIFICATION = 'ERROR_DURING_VERIFICATION',

  // geo location errors
  LAT_LONG_OUT_OF_RANGE = 'LAT_LONG_OUT_OF_RANGE',
  LAT_LONG_NOT_PROVIDED = 'LAT_LONG_NOT_PROVIDED',
  NO_LOCATION_FOUND_FOR_LAT_LONG = 'NO_LOCATION_FOUND_FOR_LAT_LONG',
  DEPARTMENT_MISSING = 'DEPARTMENT_MISSING',


  // red x with text
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  DID_NOT_MEET_REQUIREMENTS = 'DID_NOT_MEET_REQUIREMENTS'

}
