<div class="milestone-row" (click)="openMilestoneView()">
  <div class="milestone-left">
    <h4 class="milestone-title">{{ milestone.name }}</h4>
    <p *ngIf="documentsToDisplay?.length || milestone.notes?.length || milestoneForm"
       class="milestone-subtitle">
      <span *ngIf="milestoneForm">
        <span *ngIf="formValid">
          {{ 'milestones.form.form-completed' | translate }}
        </span>
        <span *ngIf="!formValid" class="text-gray">
          {{ 'milestones.form.form-incomplete' | translate }}
        </span>
        <span *ngIf="milestone.notes?.length || documentsToDisplay?.length" class="text-gray">|</span>
      </span>
      <span *ngIf="milestone.notes?.length">
        {{ milestone.notes.length }}
        <span *ngIf="milestone.notes.length === 1">{{ 'milestones.note' | translate }}</span>
        <span *ngIf="milestone.notes.length > 1">{{ 'milestones.notes' | translate }}</span>
      </span>
      <span *ngIf="milestone.notes?.length && documentsToDisplay?.length">
        {{ 'milestones.and' | translate }}
      </span>
      <span *ngIf="documentsToDisplay?.length">
        {{ documentsToDisplay.length }}
        <span *ngIf="documentsToDisplay.length === 1"> {{ 'milestones.attachment' | translate }}</span>
        <span *ngIf="documentsToDisplay.length > 1"> {{ 'milestones.attachments' | translate }}</span>
      </span>
    </p>
  </div>
  <p class="status">
    <span>{{ 'milestones.detail.status' | translate }}: </span>
    <oes-dropdown
      [ngClass]="[ 'color-' + milestoneStatus?.color ]"
      class="style-overhaul-flag sm"
      [items]="statusDropdownOptions"
      [initialSelections]="initialStatusSelection"
      [enableSearch]="false"
      [required]="true"
      [selection]="'single'"
      (click)="stopPropagation($event)"
      (selectionChanged)="statusChanged($event)">
    </oes-dropdown>
  </p>
</div>
<oes-side-drawer [isOpen]="milestoneViewShowing" (close)="closeMilestoneView()" #sideDrawer>
  <ng-container *ngIf="milestoneViewAfterAnimation">
    <oes-milestone-view
      [initialStatusSelection]="initialStatusSelection"
      [milestone]="milestone"
      [milestoneForm]="milestoneForm"
      [milestoneStatus]="milestoneStatus"
      [milestoneType]="milestoneType"
      [project]="project"
      [projectOfftaker]="projectOfftaker"
      [projectInstaller]="projectInstaller"
      [statusChanged]="statusChanged"
      [statusDropdownOptions]="statusDropdownOptions"
      (closeMilestoneView)="closeMilestoneView()"
      (formValidOutput)="formValid = $event"
    ></oes-milestone-view>
  </ng-container>
</oes-side-drawer>
