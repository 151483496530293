import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'oes-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideDrawerComponent {
  @ViewChild('contentWrapper', { read: ElementRef, static: false }) contentWrapper!: ElementRef;
  @Input() isOpen: boolean;
  @Output() close = new EventEmitter<void>();

  closeDrawer() {
    this.close.emit();
  }

  scrollChildIntoView() {
    if (this.contentWrapper && this.contentWrapper.nativeElement) {
      this.contentWrapper.nativeElement.scrollIntoView();
    }
  }
}
